import { useState, useEffect } from 'react'
import { getPath } from 'tiny-react-router'
import styled from 'styled-components'

export default function Campaign() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const path = getPath()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up: remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let res = [400, 223]
  if (windowWidth > 500) res = [560, 315]

//  if (path === '/account') {
//    return (
//      <CampaignSection>
//        <CampaignContainer>
//          <h1>NB! Ikke alle får meldingene våre på mobil 🙈</h1>
//          <CampaignText>Det ser ut til at noen mobil leverandører ikke slipper meldingene våre gjennom.<br/>
//Dette gjelder for abonnement. Dersom du bestiller et abonnement, men ikke mottar en melding innen rimelig tid;<br/>
//          </CampaignText>
//          <ul>
//            <li>Slett abonnementet</li>
//            <li>Endre preferansene over fra SMS til Epost for "Abonnement"</li>
//            <li>Opprett abonnement på ny</li>
//          </ul>
//          <h3>Takk ❤️</h3>
//        </CampaignContainer>
//      </CampaignSection>
//    ) 
//  }
//  else return null

  return ( 
      <CampaignSection>
        <CampaignImage src="/easter2025.webp" />          
      </CampaignSection>
  )
}

//        <CampaignContainer>
//          <CampaignImage src="easter2025.webp" />          
//          <h1>🎉🎉 JANUAR KAMPANJE 🎉🎉</h1>
//          <CampaignText>Alle some bestiller et abonnement i januar får 1 gratis pose kaffe ved første levering!</CampaignText>
//          <iframe width={res[0]} height={res[1]} 
//            src="https://www.youtube.com/embed/M9SsBH49jZ8?si=Xo7XbcJc-DVjt9Jy" 
//            title="YouTube video player" 
//            frameBorder="0" 
//            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
//          </iframe>
//        </CampaignContainer>

const CampaignImage = styled.img`

`

const CampaignSection = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background-color: var(--color-splash-yellow);
  padding: 20px;

  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`

const CampaignContainer = styled.div`
  padding: 0px 20px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: auto;

  & iframe {
    margin-top: 20px;
  }

  & h1 {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      font-size: 1.3em;
    }
  }
`

const CampaignText = styled.div`
  text-align: center;
`
